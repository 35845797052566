import { createGlobalStyle } from 'styled-components'

const GlobalStyles = createGlobalStyle`
  .btn {
    box-shadow: 0 0 27px 0 rgba(0,0,0,0.12);
  }
  
  .btn-link {
    box-shadow: none;
  }
  
  h1 {
    font-weight: bold;
  }
  
  .jumbotron {
    background-color: transparent;
  }
  
  .dark-background-overlay {
    background-color: rgba(0, 0, 0, 0.24);
    opacity: 1;
    transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
  }

  .darker-background-overlay {
    background-color: rgba(0, 0, 0, 0.44);
    opacity: 1;
    transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
  }
  
  .bottom-left-drop-shadow {
    box-shadow: -67px 35px 25px 0 rgba(0, 0, 0, 0.11);
  }
  
  form .form-control, form .btn {
    font-size: 1.2rem;
    letter-spacing: 1.5px;
  }

  .full-screen-jumbo {
    min-height: min(100vh, 82em);

    .name {
      font-family: 'Open Sans', sans-serif;
      font-size: 1.2em;
      letter-spacing: 2px;
      font-weight: 400;
    }

    .message {
      font-family: 'Open Sans', sans-serif;
      font-weight: 300;
      font-size: 2.8em;
      line-height: 1.2;

      strong {
        font-family: 'proxima-nova', sans-serif;
        font-weight: 400;
      }
    }
  }
`

export default GlobalStyles
