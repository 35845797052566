import React from 'react'
import BaseStyles from './src/styles/BaseStyles'

// This file is run in the browser. No ssr here.

/**
 * Gatsby Browser API: wrapPageElement
 *
 * Allow a plugin to wrap the page element.
 *
 * @param element The “Page” React Element built by Gatsby.
 * @param props Props object used by page.
 * @returns {JSX.Element}
 */
export const wrapPageElement = ({ element }) => {
    return (
        <>
            <BaseStyles />
            {element}
        </>
    )
}
